import React, { useEffect, useState } from "react";
import { ColorPicker } from "primereact/colorpicker";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCroppedImage,
  fetchHomeJsonList,
  saveThemeJsonData,
} from "../../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ImageCropper from "../../imageCropper/imageCropper";
import CreatableSelect from "react-select/creatable";

const SectionThreeThemeTwoEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const images = [
    "assets/img/home/wing1.png",
    "assets/img/home/wing2.png",
    "assets/img/home/wing3.png",
    "assets/img/home/wing4.png",
    "assets/img/home/wing1.png",
    "assets/img/home/wing2.png",
    "assets/img/home/wing3.png",
    "assets/img/home/wing4.png",
  ];
  const handleHeaderChange = (e: any) => {
    const { value, name, type, checked } = e.target;
    let cards: Array<any> = [...editData.theme_2.home.section_3.cards];
    cards[activeTab] = { [name]: value };
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        home: {
          ...prevJsonData.theme_2.home,
          section_3: {
            cards: cards,
          },
        },
      },
    }));
  };

  // const serviceChanges = (services: any) => {
  //   console.log(services);

  //   const updatedServices = services.map((option: any) => option.value);
  //   let cards: Array<any> = [...editData.theme_2.home.section_3.cards];
  //   cards[activeTab] = {
  //     ...editData.theme_2.home.section_3.cards[activeTab],
  //     services: updatedServices,
  //   };
  //   console.log(updatedServices);
  //   console.log("cards", cards);

  //   setEditData((prevJsonData: any) => ({
  //     ...prevJsonData,
  //     theme_2: {
  //       home: {
  //         ...prevJsonData.theme_2.home,
  //         section_3: {
  //           cards: cards,
  //         },
  //       },
  //     },
  //   }));
  //   console.log("editData", editData);
  // };
  const saveJsonData = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    setCropImageHeight("150");
    setCropImageWidth("300");
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink);
    }
  };
  const handleImageChange = (image: string) => {
    let updated_card_section = [...editData.theme_2.home.section_3.cards];
    updated_card_section[activeTab].bg_img = image;
    //Update the banner value in the JSON data
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        ...prevJsonData.theme_2,
        home: {
          ...prevJsonData.theme_2.home,
          section_3: {
            ...prevJsonData.theme_2.home.section_3,
            cards: updated_card_section,
          },
        },
      },
    }));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  // HTML
  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Three Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <h4 className="edit-section-hdr">Cards</h4>
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_2?.home?.section_3?.cards?.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={1}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card {index + 1}
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_2?.home?.section_3?.cards?.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.bg_img}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {images.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() => {
                                      handleImageChange(icons);
                                    }}
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Card Title</label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_title"
                              value={card.card_title}
                              onChange={handleHeaderChange}
                              maxLength={120}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Card Paragraph</label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_paragraph"
                              value={card.card_paragraph}
                              onChange={handleHeaderChange}
                              maxLength={120}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Services</label>
                            <CreatableSelect
                              isMulti
                              name="Services"
                              options={card?.services?.map((item: string) => ({
                                label: item,
                                value: item,
                              }))}
                              value={card?.services?.map((item: string) => ({
                                label: item,
                                value: item,
                              }))}
                              onChange={(selectedOptions: any) => {
                                const updatedChecklist = selectedOptions?.map(
                                  (option: any) => option.value
                                );
                                const updatedCards = [
                                  ...editData.theme_2.home.section_3.cards,
                                ];
                                updatedCards[index] = {
                                  ...updatedCards[index],
                                  services: updatedChecklist,
                                };
                                setEditData((prevJsonData: any) => ({
                                  ...prevJsonData,
                                  theme_2: {
                                    home: {
                                      ...prevJsonData.theme_2.home,
                                      section_3: {
                                        ...prevJsonData.theme_2.home.section_3,
                                        cards: updatedCards,
                                      },
                                    },
                                  },
                                }));
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">
                              Card Main Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_main_title"
                              value={card.card_main_title}
                              onChange={handleHeaderChange}
                              maxLength={120}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">
                              Card Main Paragraph
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="card_main_paragraph"
                              value={card.card_main_paragraph}
                              onChange={handleHeaderChange}
                              maxLength={120}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label className="form-label">Checklist</label>
                            <CreatableSelect
                              isMulti
                              name="checklist"
                              options={card?.check_list?.map((item: string) => ({
                                label: item,
                                value: item,
                              }))}
                              value={card?.check_list?.map((item: string) => ({
                                label: item,
                                value: item,
                              }))}
                              onChange={(selectedOptions: any) => {
                                const updatedChecklist = selectedOptions?.map(
                                  (option: any) => option.value
                                );
                                const updatedCards = [
                                  ...editData.theme_2.home.section_3.cards,
                                ];
                                updatedCards[index] = {
                                  ...updatedCards[index],
                                  check_list: updatedChecklist,
                                };
                                setEditData((prevJsonData: any) => ({
                                  ...prevJsonData,
                                  theme_2: {
                                    home: {
                                      ...prevJsonData.theme_2.home,
                                      section_3: {
                                        ...prevJsonData.theme_2.home.section_3,
                                        cards: updatedCards,
                                      },
                                    },
                                  },
                                }));
                              }}
                              className="basic-multi-select"
                              classNamePrefix="select"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
              <div className="mb-3">
                <label className="form-label">Upload Background Image</label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  className="form-control"
                  name="image"
                  onChange={updateImageSectionOne}
                />
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={saveJsonData}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionThreeThemeTwoEdit;
