import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import SectionOneThemeFive from "./section-one";
import SectionTwoThemeFive from "./section-two";
import SectionThreeThemeFive from "./section-three";
import SectionFourThemeFive from "./section-four";
import SectionFiveThemeFive from "./section-five";
import SectionSixThemeFive from "./section-six";
import SectionSevenThemeFive from "./section-seven";
import SectionEightThemeFive from "./section-eight";


function ThemeFive() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 5) {
      return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    }
  };

  return (
    <>
    {checkIsSectionEnabled(0) && <SectionOneThemeFive />}
    {checkIsSectionEnabled(1) && <SectionTwoThemeFive />}
    {checkIsSectionEnabled(2) && <SectionThreeThemeFive />}
    {checkIsSectionEnabled(3) && <SectionFourThemeFive />}
    {checkIsSectionEnabled(4) && <SectionFiveThemeFive />}
    {checkIsSectionEnabled(5) && <SectionSixThemeFive />}
    {checkIsSectionEnabled(6) && <SectionSevenThemeFive />}
    {checkIsSectionEnabled(7) && <SectionEightThemeFive />}
    </>
  );
}

export default ThemeFive;
