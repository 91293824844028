import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchHomeJsonList } from "../../redux/Actions";
// import jsonData from "../../../assets/json/home.json";
import { data } from "../edit/data";

function SectionFourThemeThree() {
  const navigate = useNavigate();
  const [userToken, setUserToken] = useState();
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  

  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
    useEffect(() => {
    }, [homedata]);


  return (
    <>
      <section className="review-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
            <h2>{homedata?.theme_3?.home?.section_4?.title}</h2>
            </div>
            <div className="col-md-5">
              <div className="review-info">
                <div className="review-star">
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <i className="fas fa-star"></i>
                  <p>
                    <a
                      className="text-white"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("reviews");
                      }}
                    >
                      Read more Reviews
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
export default SectionFourThemeThree;
