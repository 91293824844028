import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { fetchHomeJsonList, saveThemeJsonData, fetchCroppedImage } from "../../../redux/Actions";
import ImageCropper from "../../imageCropper/imageCropper";

const SectionNineBannerThemeOne = () => {

    const dispatch = useDispatch<any>();
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const [editData, setEditData] = useState<any>({
    home: {
      section_9: {
        title: "",
        paragraph: "",
        banner: [],
        card_section: {
          collection: [],
        },
      },
    },
  });
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const banner = [
    "assets/img/faqimg.jpg",
    "assets/img/sliderbg.jpg",
    "assets/img/testimonialimg1.jpg",
    "assets/img/testimonialimg2.jpg",
    "assets/img/faqimg.jpg",
    "assets/img/sliderbg.jpg",
    "assets/img/testimonialimg1.jpg",
  ];

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const handleChange = (e: any, index?: any) => {
  const { value, name } = e.target;
  if (index !== undefined) {
    setEditData((prevJsonData: any) => {
      const updatedCollection = prevJsonData.theme_1.home.section_9.card_section.collection.map(
        (card: any, cardIndex: number) => {
          if (cardIndex === index) {
            return { ...card, [name]: value };
          }
          return card;
        }
      );

      return {
        ...prevJsonData,
        theme_1: {
            ...prevJsonData.theme_1,
        home: {
          ...prevJsonData.theme_1.home,
          section_9: {
            ...prevJsonData.theme_1.home.section_9,
            card_section: {
              ...prevJsonData.theme_1.home.section_9.card_section,
              collection: updatedCollection,
            },
          },
        },
    },
      };
    });
  } else {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_9: {
          ...prevJsonData.theme_1.home.section_9,
          [name]: value,
        },
      },
    },
    }));
  }
};

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [...editData.theme_1.home.section_9.banner];
    updatedCollection[index] = {
      ...updatedCollection[index],
      banner: image,
    };
  
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_9: {
          ...prevJsonData.theme_1.home.section_9,
          banner: updatedCollection,
          
        },
      },
    },
    }));
  };
    
  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_1.home.section_9.card_section.collection,
    ];

    let newCard = {
      collection_title: "",
      collection_paragraph: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_9: {
          ...prevJsonData.theme_1.home.section_9,
          card_section: {
            btn_group: {
              ...prevJsonData.theme_1.home.section_9.card_section.btn_group,
            },
            collection: updatedCollection,
          },
        },
      },
    },
    }));
  };
  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_1.home.section_9.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1: {
        ...prevJsonData.theme_1,
      home: {
        ...prevJsonData.theme_1.home,
        section_9: {
          ...prevJsonData.theme_1.home.section_9,
          card_section: {
            btn_group: {
              ...prevJsonData.theme_1.home.section_9.card_section.btn_group,
            },
            collection: updatedCollection,
          },
        },
      },
    },
    }));
  };


  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      handleImageChange(imageLink,activeTab)
    }
  };


const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    setCropImageHeight("735")
    setCropImageWidth("880")
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  return(
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Section Nine Theme Edit 
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 120 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_1?.home?.section_9?.title}
                      onChange={handleChange}
                      maxLength={120}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_1?.home?.section_9?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                    <div className="mb-3">
                        <div className="row">
                            <div className="col-md-12">
                            {editData?.theme_1?.home?.section_9?.banner?.map(
                                (card: any, index: any) => (
                                  <>
                                <div className="img-bg active">
                                  
                                <img src={card.banner} alt={`Card Icon ${index + 1}`} />
                                </div>
                            <div className="img-options">
                                <div className="row">
                                <div className="col-md-12">
                                    <Swiper
                                    spaceBetween={15}
                                    navigation={true}
                                    modules={[Navigation]}
                                    breakpoints={{
                                        640: {
                                        slidesPerView: 1,
                                        },
                                        768: {
                                        slidesPerView: 4,
                                        },
                                        1024: {
                                        slidesPerView: 6,
                                        },
                                    }}
                                    className="homejson-slider"
                                    >
                                 {banner.map(
                                              (banner, iconIndex) => (
                                                <SwiperSlide key={iconIndex}>
                                                  <div
                                                    className="img-option"
                                                    onClick={() =>
                                                      handleImageChange(
                                                        banner,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <img
                                                      src={banner}
                                                      alt="Hero Image"
                                                      className="img-fluid"
                                                    />
                                                  </div>
                                                </SwiperSlide>
                                              )
                                            )}
                                   
                                    </Swiper>
                                </div>
                                </div>
                            </div>
                            </>
                             )
                             )}
                            </div>
                        </div>
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Upload Image</label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        name="image"
                        onChange={updateImageSectionOne}
                      />
                    </div>
                    <div className="mb-3">
                    <label className="form-label">
                      Card Heading<small>(max 120 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="card_heading"
                      value={editData?.theme_1?.home?.section_9?.card_heading}
                      onChange={handleChange}
                      maxLength={120}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Telephone Number</label>
                    <input
                      type="text"
                      className="form-control"
                      name="card_number"
                      value={editData?.theme_1?.home?.section_9?.card_number}
                      onChange={handleChange}
                      maxLength={20}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Button Text</label>
                    <input
                      type="text"
                      className="form-control"
                      name="card_buttontext"
                      value={editData?.theme_1?.home?.section_9?.card_buttontext}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_1?.home?.section_9?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Q&A Section {index + 1}
                          </a>
                          <a className="tab-close" onClick={() => {removeCollection(index)}}>
                            <i
                              className="fas fa-times"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_1?.home?.section_9?.card_section?.collection.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">

                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                        Question<small>(max 120 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_title"
                          value={card.collection_title}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={120}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Answer</label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_paragraph"
                          value={card.collection_paragraph}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={1200}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>

            </div>
            <div className="mb-3">
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
      
        </div>
      </div>
    {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
  </>
  )
}

export default SectionNineBannerThemeOne;