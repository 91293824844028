import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import {
  cleancloths,
  appstore,
  googleplay,
  heroimg,
  washwave,
} from "../../assets/img";
import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(UKPostalCode, "Enter a valid Pin code")
    .trim()
    .max(8, "Enter a valid Pin code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

function SectionOneThemeFour() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const navigate = useNavigate();
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });

  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };
  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);

  const submitAddressChange = async () => {
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          navigate("/productLists");
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        navigate("/productLists");
      }
    }
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };
  return (
    <>
      <div
        className="home_single_search"
        style={{
          background: `url(${jsonData?.theme_4?.home?.section_1?.banner}) no-repeat top center`,
        }}
      >
        {!settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") && (
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-7">
              <div className="banner-search-wrap">
                <span className="banner-tag">
                  <img src={cleancloths} alt="" className="me-2" />{" "}
                  {jsonData?.theme_4?.home?.section_1?.sub_heading_top}
                </span>
                <h1>{jsonData?.theme_4?.home?.section_1?.title}</h1>
                <div className="search-wrapper">
                  <h4>{jsonData?.theme_4?.home?.section_1?.paragraph}</h4>
                  <div className="postcodeform-container">
                    <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                      <div
                        className={`${
                          checkWithNewPin == false ? "d-none" : ""
                        }`}
                      >
                        <small className="text-danger postvalidation">
                          {pinCodeFormError.pinCode?.message}
                        </small>
                        <div className="postcodeform">
                          <Controller
                            name="pinCode"
                            control={pinCodeFormControl}
                            render={({ field }) => (
                              <input
                                type="text"
                                id="postcode-input"
                                {...field}
                                placeholder="Enter Postcode"
                                className={`ui-autocomplete-input ${
                                  pinCodeFormError.pinCode ? "invalid" : ""
                                }`}
                              />
                            )}
                          />
                          <button
                            className="btn hover-btn"
                            id="book_now_btn"
                            type="submit"
                          >
                            Order Now
                          </button>
                        </div>
                      </div>
                    </form>
                    <form>
                      <div
                        className={`adrsselectform ${
                          checkWithNewPin == true ? "d-none" : ""
                        }`}
                      >
                        <small className="text-danger postvalidation">
                          {addressFormError.address?.message}
                        </small>
                        <div className="adrsselectdd">
                          <Controller
                            name="address"
                            control={addressFormControl}
                            render={({ field }) => (
                              <Select
                                placeholder="Select your address"
                                {...field}
                                value={selectedAddress}
                                className={`${
                                  addressFormError.address ? "invalid" : ""
                                }`}
                                onChange={handleAddressChange}
                                onFocus={() => {
                                  if (
                                    getValuesAddressForm("address").value == ""
                                  ) {
                                    addressFormSetError("address", {
                                      type: "server",
                                      message: "Address is required to proceed",
                                    });
                                  } else {
                                    clearErrors();
                                  }
                                }}
                                options={areaOptions}
                              />
                            )}
                          />

                          <input
                            type="button"
                            value="Continue"
                            className="btn hover-btn"
                            onClick={submitAddressChange}
                          />
                        </div>
                        <div className="adrsselect-or">OR</div>
                        <div className="adrsselect-link">
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setCheckWithNewPin(true);
                            }}
                          >
                            Check with another Postcode
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="banner-app d-none">
                  <h4>Just A Click Away</h4>
                  <ul className="banner-app-links">
                    {settingsData != null &&
                      Object.keys(settingsData).length > 0 &&
                      settingsData?.WebmasterSettings.app_store_link !== "" && (
                        <li>
                          <a
                            target="_blank"
                            href={
                              settingsData?.WebmasterSettings.app_store_link
                            }
                          >
                            <img src={appstore} alt="" />
                          </a>
                        </li>
                      )}
                    {settingsData != null &&
                      Object.keys(settingsData).length > 0 &&
                      settingsData?.WebmasterSettings.play_store_link !==
                        "" && (
                        <li>
                          <a
                            target="_blank"
                            href={
                              settingsData?.WebmasterSettings.play_store_link
                            }
                          >
                            <img src={googleplay} alt="" />
                          </a>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="heroimg">
                {jsonData?.theme_4?.home?.section_1?.banner_type == "image" ? (
                  <img
                    src={jsonData?.theme_4?.home?.section_1?.banner_two}
                    alt=""
                    className="img-fluid"
                  />
                ) : (
                  <>
                    <video
                      loop
                      autoPlay
                      className="img-fluid"
                      src={jsonData?.theme_4?.home?.section_1?.banner_two}
                    ></video>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        )}
      </div>
      {settingsData?.WebsiteSettings?.site_title_en.includes("Whiterose") && (
      <div className="homesearch" id="book-now">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md">
              <div className="homesearch-grid">
                <div className="washwave">
                  <img src={washwave} alt="" />
                  <h6>Book a service</h6>
                </div>
                <div className="search-wrapper">
                  <h4 className="searchh4">{jsonData?.theme_4?.home?.section_1?.paragraph}</h4>
                  <div className="postcodeform-container">
                    <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                      <div
                        className={`${
                          checkWithNewPin == false ? "d-none" : ""
                        }`}
                      >
                        <small className="text-danger postvalidation">
                          {pinCodeFormError.pinCode?.message}
                        </small>
                        <div className="postcodeform">
                          <Controller
                            name="pinCode"
                            control={pinCodeFormControl}
                            render={({ field }) => (
                              <input
                                type="text"
                                id="postcode-input"
                                {...field}
                                placeholder="Enter Postcode"
                                className={`ui-autocomplete-input ${
                                  pinCodeFormError.pinCode ? "invalid" : ""
                                }`}
                              />
                            )}
                          />
                          <button
                            className="btn hover-btn"
                            id="book_now_btn"
                            type="submit"
                          >
                            Order Now
                          </button>
                        </div>
                      </div>
                    </form>
                    <form>
                      <div
                        className={`adrsselectform ${
                          checkWithNewPin == true ? "d-none" : ""
                        }`}
                      >
                        <small className="text-danger postvalidation">
                          {addressFormError.address?.message}
                        </small>
                        <div className="adrsselectdd">
                          <Controller
                            name="address"
                            control={addressFormControl}
                            render={({ field }) => (
                              <Select
                                placeholder="Select your address"
                                {...field}
                                value={selectedAddress}
                                className={`${
                                  addressFormError.address ? "invalid" : ""
                                }`}
                                onChange={handleAddressChange}
                                onFocus={() => {
                                  if (
                                    getValuesAddressForm("address").value == ""
                                  ) {
                                    addressFormSetError("address", {
                                      type: "server",
                                      message: "Address is required to proceed",
                                    });
                                  } else {
                                    clearErrors();
                                  }
                                }}
                                options={areaOptions}
                              />
                            )}
                          />

                          <input
                            type="button"
                            value="Continue"
                            className="btn hover-btn"
                            onClick={submitAddressChange}
                          />
                        </div>
                        <div className="adrsselect-or">OR</div>
                        <div className="adrsselect-link">
                          <a
                            className="cursor-pointer"
                            onClick={() => {
                              setCheckWithNewPin(true);
                            }}
                          >
                            Check with another Postcode
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
       )}
    </>
  );
}

export default SectionOneThemeFour;
