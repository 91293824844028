import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchPickupAdress,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import * as yup from "yup";
// import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";

// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

const UKPostalCode: RegExp =
  /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/;

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(UKPostalCode, "Enter a valid Pin code")
    .trim()
    .max(8, "Enter a valid Pin code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

function SectionOneThemeFive() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const navigate = useNavigate();
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  useEffect(() => {}, [jsonData]);

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });

  // const base_url = "https://revamp.dreamguystech.com"

  // const fetchData = async () => {
  //     const base_url = "https://revamp.dreamguystech.com";
  //     try {
  //         const bodyFormData = new FormData();
  //         bodyFormData.append('keyword', 'Gu11');
  //         // res.header('Access-Control-Allow-Methods', 'GET, POST');
  //         const response = await axios({
  //             method: "post",
  //             url: `${base_url}/api/get_postal_codes`,
  //             data: bodyFormData,
  //             headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST' },
  //         })
  //         // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
  //         // const result = await response.then(response => response);

  //         if (response) {
  //             setPostalCodeList(response.data.data[0])
  //
  //         }
  //     } catch (error) {
  //
  //     }
  // }

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);

  // useEffect(() => {
  //     // fetchData();
  //     dispatch(fetchPostalCodes())
  //     // dispatch(fetchPostalCodes());
  // }, [])

  //   const handleChange = (e: any) => {
  //     const result = e.target.value.replace(/^\s+|\s+$|\s+(?=\s)/g, "");
  //     // setPostalCodeList(e.target.value);
  //     const { value } = e.target;
  //     const updateValue = value.replace(/\s/g, "");
  //     if (value.length > 0) {
  //       dispatch(fetchPostalCodes(updateValue));
  //     }
  //     setPostalCodeValue(updateValue);
  //   };
  const onSearch = (searchTerm: any) => {
    // setPostalCodeList(searchTerm);
    setPostalCodeValue(searchTerm);
    setValue("postalCode", searchTerm.toUpperCase());
    // dispatch(fetchPostalCodes(searchTerm));
  };
  //   const handleBooknow = async () => {
  //     const isValid = state.postalCodeList.some((item:any) => item.postcode === postalCodeValue.toUpperCase());
  //     setValue("postalCode",postalCodeValue.toUpperCase())
  //     const results = await dispatch(fetchGetShopByPinCode(JSON.stringify(postalCodeValue)));
  //     if (!results) {
  //         navigate("/areaNotCovered");
  //         return;
  //     }
  //     if(isValid){
  //         navigate("/productLists");
  //     }else{
  //         navigate("/areaNotCovered");
  //     }
  //   };
  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };

  const submitAddressChange = async () => {
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          navigate("/productLists");
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        navigate("/productLists");
      }
    }
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };

  return (
    <div
      className="home_single_search theme5"
      style={{
        background: `url(${jsonData?.theme_5?.home?.section_1?.banner}) no-repeat top center`,
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div className="banner-search-wrap">
              <span className="banner-tag">
                {/* <img src={jsonData?.theme_5?.home?.section_1?.banner} alt="" className="me-2" />{" "} */}
              </span>
              <h1>{jsonData?.theme_5?.home?.section_1?.title}</h1>

              {/* <h1>
                A Professional <span>Dry Cleaning &amp; Laundry Service</span>{" "}
                in the UK
              </h1> */}
              <p className="d-none d-md-block">
                {jsonData?.theme_5?.home?.section_1?.paragraph}
              </p>
              <div className="postcodeform-container">
                <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                  <div
                    className={`${checkWithNewPin == false ? "d-none" : ""}`}
                  >
                    <small className="text-danger postvalidation">
                      {pinCodeFormError.pinCode?.message}
                    </small>
                    <div className="postcodeform">
                      <Controller
                        name="pinCode"
                        control={pinCodeFormControl}
                        render={({ field }) => (
                          <input
                            type="text"
                            id="postcode-input"
                            {...field}
                            placeholder={
                              jsonData?.theme_5?.home?.section_1?.btn_group
                                ?.btn1?.placeholder
                            }
                            className={`ui-autocomplete-input ${
                              pinCodeFormError.pinCode ? "invalid" : ""
                            }`}
                          />
                        )}
                      />
                      <button
                        className="btn hover-btn"
                        id="book_now_btn"
                        type="submit"
                        style={{
                          background: `#${jsonData?.theme_5?.home?.section_1?.btn_group?.btn1?.bg_color}`,
                          color: `#${jsonData?.theme_5?.home?.section_1?.btn_group?.btn1?.color}`,
                        }}
                      >
                        {
                          jsonData?.theme_5?.home?.section_1?.btn_group?.btn1
                            ?.text
                        }
                      </button>
                    </div>
                  </div>
                </form>
                <form>
                  <div
                    className={`adrsselectform ${
                      checkWithNewPin == true ? "d-none" : ""
                    }`}
                  >
                    <small className="text-danger postvalidation">
                      {addressFormError.address?.message}
                    </small>
                    <div className="adrsselectdd">
                      <Controller
                        name="address"
                        control={addressFormControl}
                        render={({ field }) => (
                          <Select
                            placeholder="Select your address"
                            {...field}
                            value={selectedAddress}
                            className={`${
                              addressFormError.address ? "invalid" : ""
                            }`}
                            onChange={handleAddressChange}
                            onFocus={() => {
                              if (getValuesAddressForm("address").value == "") {
                                addressFormSetError("address", {
                                  type: "server",
                                  message: "Address is required to proceed",
                                });
                              } else {
                                clearErrors();
                              }
                            }}
                            options={areaOptions}
                          />
                        )}
                      />

                      <input
                        type="button"
                        value="Continue"
                        className="btn hover-btn"
                        onClick={submitAddressChange}
                      />
                    </div>
                    <div className="adrsselect-or">OR</div>
                    <div className="adrsselect-link">
                      <a
                        className="cursor-pointer"
                        onClick={() => {
                          setCheckWithNewPin(true);
                        }}
                      >
                        Check with another Postcode
                      </a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-5 d-none d-lg-block">
            <div className="heroimg">
              {jsonData?.theme_5?.home?.section_1?.banner_type == "image" ? (
                <img
                  src={jsonData?.theme_5?.home?.section_1?.banner_two}
                  alt=""
                  className="img-fluid"
                />
              ) : (
                <>
                  <video
                    loop
                    autoPlay
                    className="img-fluid"
                    src={jsonData?.theme_5?.home?.section_1?.banner_two}
                  ></video>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOneThemeFive;
