import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import "./assets/plugins/fontawesome/css/all.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "../node_modules/@iconscout/unicons/css/line.css";
import reportWebVitals from "./reportWebVitals";
import App from "./components/App";
import { BrowserRouter } from "react-router-dom";
import "primereact/resources/primereact.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckOut from "./components/checkout";
import Whatsapp from "./components/whatsapp";
import Livechat from "./components/liveChat/liveChat";
import Loader from "./components/loader";
import { useEffect, useState } from "react";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// // recreating the `Stripe` object on every render.
// const stripePromise = loadStripe('pk_test_1ebiWNZAHyH9B2SF8VK9eNPf');
// const options:any= {
//   // passing the client secret obtained from the server
//   clientSecret: '{{CLIENT_SECRET: "xcUe6t9Zn2dvnfdFtlzsFqsZ"}}',
// };
// const stripePromise = loadStripe('pk_test_1ebiWNZAHyH9B2SF8VK9eNPf');

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        {/* <Elements stripe={stripePromise}> */}

        <Loader />
        <App />
        {/* </Elements> */}
        <Whatsapp />
        {/* <Livechat/> */}
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
