import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  priorityicon1,
  priorityicon2,
  priorityicon3,
  priorityicon4,
  priorityicon5,
  priorityicon6,
} from "../../assets/img";

function SectionThreeThemeThree() {
  const navigate = useNavigate();
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  return (
    <>
      <section className="priority-section">
        <div className="section-title">
          <span className="yellow-btn">
            {" "}
            {homedata?.theme_3?.home?.section_3?.sub_heading_top}
          </span>
          <h2>{homedata?.theme_3?.home?.section_3?.title}</h2>
          <p>{homedata?.theme_3?.home?.section_3?.paragraph}</p>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            {homedata?.theme_3?.home?.section_3?.card_section?.collection.map(
              (items: any) => {
                return (
                  <>
                    <div className="col-md-4">
                      <div className="priority-card text-center">
                        <span className="priority-card-icon">
                          <img src={items.icon} />
                        </span>
                        <h3> {items.collection_title} </h3>
                        <p>{items.collection_paragraph}</p>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="text-center load-more d-none">
                <Link
                  style={{
                    background: `#${homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1?.bg_color}`,
                    color: `#${homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1?.color}`,
                  }}
                  onClick={() => navigate("/aboutUs")}
                  className="btn title-btn hover-btn"
                  to={""}
                >
                  {
                    homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1
                      ?.text
                  }
                  <i className="uil uil-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default SectionThreeThemeThree;
