import React, { useEffect, useState } from 'react';
// import MyProfileHeader from '../../myProfileHeader';
// import MyFirstFlat from '../../myFirstFlat';
import './../myProfile/myProfile.css';
import './../myProfile/deleteProfilePopup.css';
import DeletePopup from '../../deletePopup';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteProfilePopup from './deleteProfilePopup';
import { ToastContainer } from 'react-toastify';
import { myprofile2,myorders,myaddress,logout2 } from '../../../assets/img'

function MyProfile() {

    const [profile, setProfile] = useState({})
    const [deleteChange, setDeleteChange] = useState(false)
    const state: any = useSelector(state => state);
    const navigate = useNavigate();

    const rand = Math.random();

    const token = localStorage.getItem("token");
    useEffect(() => {
        const base_url = process.env.REACT_APP_BACKEND_URL;
        axios({
            method: "get",
            url: `${base_url}/api/my_profile`,
            headers: { "Accept": "application/json", 'Access-Control-Allow-Methods': 'GET, POST', 'Authorization': 'Bearer ' + token },
        }).then((response) => {
            if (response.status != 401) {
                setProfile(response.data.data);
                localStorage.setItem("username",response.data.data.name)
            }
        }).catch(function (error) {
            
        })

    }, [state])

    const handleLogout = (e: any) => {
        e.preventDefault();
        localStorage.clear();
        navigate("/login")
        window.location.reload();
    }

    // const profileData: Profile = {profile};

    // const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
    //     obj[key];

    // interface Profile {
    //     id: number;
    //     name: string;
    //     email: string;
    //     mobile: string;
    //     photo: string;
    // }

    const profileData: { [unit: string]: number } = profile;

    return (
        <div>
            <div className="breadcrumpset">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumpview">
                                <h2>My Profile</h2>
                                <ul>
                                    <li>
                                        <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                                    </li>
                                    <li>
                                        <span> My Profile</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-myprofile">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 custom-myprofile">
                            <h1>My Profile</h1>
                            <span className="float-end"><a href='#' onClick={(e) => {e.preventDefault(); navigate('/')}}><i className="fa fa-angle-double-left" aria-hidden="true"></i>Back to home</a></span>
                        </div>
                    </div>
                    {/* {getKeyValue<keyof Profile, Profile>("name")(profile)} */}
                                    {/* <h5>{}</h5> */}
                    <div className="row">
                        <div className="col-md-4">
                            <div className="user-profile" >
                                <div className="user-profile-details">
                                    <span className="db-img">
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigate('/editProfile')}} ><i className="fas fa-pencil-alt" aria-hidden="true"></i></a>
                                    </span>
                                    <img className='user-profile-img' src={`${profileData.web_photo}?bust=${rand}`} />
                                    <h4><small>Hello,</small> {profileData.name}</h4>
                                </div>
                                <div className="orders-account">
                                    <div className="myorders" onClick={() => navigate('/myProfile')}>
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigate('/myProfile')}}>
                                            <i className="fas fa-layer-group"></i>
                                            <h4>My Profile</h4>
                                        </a>
                                    </div>
                                    <div className="myorders" onClick={() => navigate('/myOrders')} >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigate('/myOrders')}}>
                                            <i className="fas fa-tag"></i>
                                            <h4>My Services</h4>
                                        </a>
                                    </div>
                                    {/* <div className="myorders" onClick={() => navigate('/myAddress')}  >
                                        <a href='#' onClick={(e) => {e.preventDefault(); navigate('/myAddress')}}>
                                            <i className="fas fa-map-marker-alt"></i>
                                            <h4>My Address</h4>
                                        </a>
                                    </div> */}
                                    {/* <div className="myorders">
                                        <button className="btn btn-danger remove_option m-sm-1" type="button" data-bs-toggle="modal" data-bs-target="#deleteprofile" data-id="1" onClick={() => setDeleteChange(!deleteChange)}><i className="fa fa-trash"></i></button>
                                        <h4>Delete Profile</h4>
                                    </div> */}
                                    <div className="myorders">
                                        <a href='#' onClick={(e) => { handleLogout(e) }}>
                                            <i className="fas fa-power-off"></i>
                                            <h4>Logout</h4>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            
                        </div>
                        <div className="col-md-8 col-sm-6">
                            <div className="profile-details-right">
                                <div className="profileinfo-header">
                                    <h5>Personal Information</h5>
                                    <span className="float-end"><a href='#' onClick={(e) => {e.preventDefault(); navigate('/editProfile')}}><i className="fas fa-pencil-alt" aria-hidden="true"></i></a></span>
                                </div>
                                <div className="personal-info">
                                    <label>Name:</label>
                                    <p>{profileData.name}</p>
                                </div>
                                <div className="personal-info">
                                    <label>Email Address:</label>
                                    <p>{profileData.email}</p>
                                </div>
                                <div className="personal-info">
                                    <label>Phone Number:</label>
                                    <p>{profileData.mobile}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {deleteChange && <DeleteProfilePopup close={() => setDeleteChange(false)} />}
            <ToastContainer />
        </div>
    )
}

export default MyProfile;
