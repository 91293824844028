import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionOneBannerThemeOne from "./SectionOneBannerThemeOne";
import SectionThreeThemeOne from "./sectionThreeThemeOne";
import SectionFourThemeOne from "./sectionFourThemeOne";
import SectionFiveThemeOne from "./SectionFiveThemeOne";
import SectionSixThemeOne from "./sectionSixThemeOne";
import SectionSevenThemeOne from "./sectionSevenThemeOne";
import SectionEightThemeOne from "./SectionEightThemeOne";
import SectionNineThemeOne from "./sectionNineThemeOne";
import SectionTenBannerThemeOne from "./SectionTenBannerThemeOne";
import SectionTwoBannerThemeOne from "./SectionTwoBannerThemeOne";
import SectionOffer from "./section-offer";

function ThemeOne() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 1) {
      return jsonData?.theme_1?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 3) {
      return jsonData?.theme_3?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 5) {
      return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    }
  };

  return (
    <>
      {/* Theme One */}
      {!settingsData?.WebsiteSettings?.site_title_en.includes("Sasian") &&
        !settingsData?.WebsiteSettings?.site_title_en.includes("Peter Borough") && (
          <>
            {checkIsSectionEnabled(0) && <SectionOneBannerThemeOne />}
            {/* {checkIsSectionEnabled(1) && <SectionTwoBannerThemeOne />} */}
            {checkIsSectionEnabled(2) && <SectionThreeThemeOne />}
            {checkIsSectionEnabled(3) && <SectionFourThemeOne />}
            {checkIsSectionEnabled(4) && <SectionFiveThemeOne />}
            {checkIsSectionEnabled(5) && <SectionSixThemeOne />}
            {checkIsSectionEnabled(6) && <SectionSevenThemeOne />}
            {checkIsSectionEnabled(7) && <SectionEightThemeOne />}
            {checkIsSectionEnabled(8) && <SectionNineThemeOne />}
            {checkIsSectionEnabled(9) && <SectionTenBannerThemeOne />}
          </>
        )}
      {settingsData?.WebsiteSettings?.site_title_en.includes("Peter Borough") && (
        <>
          {checkIsSectionEnabled(0) && <SectionOneBannerThemeOne />}
          {checkIsSectionEnabled(6) && <SectionSevenThemeOne />}
          {checkIsSectionEnabled(2) && <SectionThreeThemeOne />}
          {checkIsSectionEnabled(7) && <SectionEightThemeOne />}
          {checkIsSectionEnabled(8) && <SectionNineThemeOne />}
          {checkIsSectionEnabled(9) && <SectionTenBannerThemeOne />}
          {/* {checkIsSectionEnabled(1) && <SectionTwoBannerThemeOne />} */}
          {/* {checkIsSectionEnabled(3) && <SectionFourThemeOne />}
          {checkIsSectionEnabled(4) && <SectionFiveThemeOne />}
          {checkIsSectionEnabled(5) && <SectionSixThemeOne />} */}
        </>
      )}
      {settingsData?.WebsiteSettings?.site_title_en.includes("Sasian") && (
        <>
          {checkIsSectionEnabled(6) && <SectionSevenThemeOne />}
          {checkIsSectionEnabled(5) && <SectionSixThemeOne />}

          {/* {checkIsSectionEnabled(1) && <SectionTwoBannerThemeOne />} */}
          {checkIsSectionEnabled(2) && <SectionThreeThemeOne />}
          {checkIsSectionEnabled(0) && <SectionOneBannerThemeOne />}
          {checkIsSectionEnabled(7) && <SectionEightThemeOne />}
          {checkIsSectionEnabled(3) && <SectionFourThemeOne />}
          {checkIsSectionEnabled(4) && <SectionFiveThemeOne />}
          {checkIsSectionEnabled(9) && <SectionTenBannerThemeOne />}

          {checkIsSectionEnabled(8) && <SectionNineThemeOne />}
        </>
      )}
      {/* Theme One */}
    </>
  );
}

export default ThemeOne;
