import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { data } from "../data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"; 
import { Navigation } from "swiper";
import { trustimg, trustimg2,trustimg3,trustimg4,priorityicon7,priorityicon8,priorityicon9 } from "../../../assets/img";
import { fetchHomeJsonList, saveThemeJsonData, fetchCroppedImage } from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import ImageCropper from "../../imageCropper/imageCropper";

function SectionTenThemeFourEdit() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const data: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const icons = [
    "assets/home/testimonialuser1.jpg",
    "assets/home/testimonialuser2.jpg",
    "assets/home/testimonialuser3.jpg",
    "assets/home/testimonialuser1.jpg",
    "assets/home/testimonialuser2.jpg",
    "assets/home/testimonialuser3.jpg",
    "assets/home/testimonialuser1.jpg",
    "assets/home/testimonialuser2.jpg",
    "assets/home/testimonialuser3.jpg",
  ]
  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(data);
  }, [data]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData)
    }
  }, [editData]);

  
  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_10.review_card,
    ];

    let newCard = {
      reviewer_pic: "assets/home/testimonialuser1.jpg",
      reviewer_name: "",
      rating: "",
      review_highlight: "",
      review: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
      home: {
        ...prevJsonData.theme_4.home,
        section_10: {
          ...prevJsonData.theme_4.home.section_10,
            review_card: updatedCollection,
        },
      },
    },
    }));
  };

  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_10.review_card,
    ];
    updatedCollection.splice(index, 1);
    setActiveTab(activeTab - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
      home: {
        ...prevJsonData.theme_4.home,
        section_10: {
          ...prevJsonData.theme_4.home.section_10,                
            review_card: updatedCollection,
        },
      },
    },
    }));
  };

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [
      ...editData.theme_4.home.section_10.review_card,
    ];
    if (index != undefined) {
      updatedCollection = [...editData.theme_4.home.section_10.review_card];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
      home: {
        ...prevJsonData.theme_4.home,
        section_10: {
          ...prevJsonData.theme_4.home.section_10,
          [name]: value,
          title:
            name === "section_10.title"
              ? value
              : prevJsonData.theme_4.home.section_10.title,
              sub_heading_top:
            name === "section_10.sub_heading_top"
              ? value
              : prevJsonData.theme_4.home.section_10.sub_heading_top,
              paragraph:
            name === "section_10.paragraph"
              ? value
              : prevJsonData.theme_4.home.section_10.paragraph,
              review_bottom_paragraph:
              name === "section_10.review_bottom_paragraph"
              ? value
              : prevJsonData.theme_4.home.section_10.review_bottom_paragraph,
              review_card: updatedCollection,
        },
      },
    },
    }));
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [
      ...editData.theme_4.home.section_10.review_card,
    ];
    updatedCollection[index] = {
      ...updatedCollection[index],
      reviewer_pic: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        ...prevJsonData.theme_4,
      home: {
        ...prevJsonData.theme_4.home,
        section_10: {
          ...prevJsonData.theme_4.home.section_10,
            ...prevJsonData.theme_4.home.section_10.review_card,
            review_card: updatedCollection,
        },
      },
    },
    }));
  };
  
  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if(imageLink != false){
      handleImageChange(imageLink,activeTab)
    }
  };


const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    setCropImageHeight("150")
    setCropImageWidth("150")
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  return (
    <>
        <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Section10 Theme Edit 
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-content modal-body">
              <div className="edit-section">
                <div className="row">
                  <div className="col">
                    <div className="mb-3">
                      <label className="form-label">
                        Title<small>(max 120 char)</small>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="section_10.title"
                        value={editData?.theme_4?.home?.section_10?.title}
                        onChange={handleChange}
                        maxLength={120}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Paragraph<small>(max 1200 char)</small></label>
                      <input
                        type="text"
                        className="form-control"
                        name="section_10.paragraph"
                        value={editData?.theme_4?.home?.section_10?.paragraph}
                        onChange={handleChange}
                        maxLength={1200}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="edit-section">
                <ul className="nav nav-tabs">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      1024: {
                        slidesPerView: 3,
                      },
                    }}
                    className="homejson-slider"
                  >
                    {editData?.theme_4?.home?.section_10?.review_card.map(
                      (card: any, index: any) => (
                        <SwiperSlide key={index}>
                          <li key={index} className="nav-item nav-option">
                            <a
                              className={`nav-link ${
                                activeTab === index ? "active" : ""
                              }`}
                              onClick={() => setActiveTab(index)}
                            >
                              Card Section {index + 1}
                            </a>
                            <a className="tab-close" onClick={() => {removeCollection(index)}}>
                              <i
                                className="fas fa-times"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </ul>
                <div className="tab-content">
                  {editData?.theme_4?.home?.section_10?.review_card.map(
                    (card: any, index: any) => (
                      <div
                        key={index}
                        className={`tab-pane fade ${
                          activeTab === index ? "show active" : ""
                        }`}
                      >
                        <div className="row">
                          <div className="col-md-12">
                            <div className="img-bg active">
                              <img
                                src={card.reviewer_pic}
                                alt={`Card Icon ${index + 1}`}
                              />
                            </div>
                            <div className="img-options w-100">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {icons.map((icons, iconIndex) => (
                                  <SwiperSlide key={iconIndex}>
                                    <div
                                      className="img-option"
                                      onClick={() =>
                                        handleImageChange(icons, index)
                                      }
                                    >
                                      <img
                                        src={icons}
                                        alt="Hero Image"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Name<small>(max 120 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="reviewer_name"
                            value={card.reviewer_name}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={120}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Rating<small>(1 to 5)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="rating"
                            value={card.rating}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={1}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                          Review Highlight<small>(max 120 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="review_highlight"
                            value={card.review_highlight}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={120}
                          />
                        </div>
                        <div className="mb-3">
                          <label className="form-label">
                            Review<small>(max 1200 char)</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="review"
                            value={card.review}
                            onChange={(e) => handleChange(e, index)}
                            maxLength={1200}
                          />
                        </div>
                        <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="add-section">
                <div className="row">
                  <div
                    onClick={() => {
                      addCollection();
                    }}
                    className="col text-center"
                  >
                    Add Card<i className="fas fa-plus-square ms-2"></i>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                <button
                  type="submit"
                  data-bs-dismiss="modal"
                  className="btn primary-btn"
                  onClick={() => {
                    saveJsonDataToFile();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
        {showCropModal ? (
          <>
            <div
              className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
              id="crop-modal"
              tabIndex={-1}
              aria-labelledby="crop-modal"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <ImageCropper
                src={selectedCropImage}
                height={cropImageHeight}
                width={cropImageWidth}
                handleCrop={handleCroppedImage}
                close={() => {
                  setShowCropModal(false);
                }}
              />
            </div>
          </>
        ) : null}
    </>
  );
}

export default SectionTenThemeFourEdit;
