import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../redux/Actions/checkoutPageActions";
import {
  vehicle,
  banner1Them2,
  banner2Them2,
  banner3,
  banner4,
  banner5,
} from "../../assets/img";
import * as yup from "yup";
import { UKPostalCode } from "../core/regex";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";


const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(UKPostalCode, "Enter a valid Pin code")
    .trim()
    .max(8, "Enter a valid Pin code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

function SectionOneThemeTwo() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
    }
  }, [state]);

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });
  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };
  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);

  useEffect(() => {
    if (checkWithNewPin == false) {
      setPinCodeFormValue("pinCode", "");
      setSelectedAddress(null);
      resetAddressForm();
    } else {
    }
  }, [checkWithNewPin]);

  const submitAddressChange = async () => {
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          navigate("/productLists");
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        navigate("/productLists");
      }
    }
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };

  return (
    <>
      <div className="home_single_search">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="banner-search-wrap text-center">
                <p>{jsonData?.theme_2?.home?.section_1?.title_1}</p>
                <h1>{jsonData?.theme_2?.home?.section_1?.title_2}</h1>
                <p>{jsonData?.theme_2?.home?.section_1?.title_3}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="home-img-grid">
                <ul>
                  {jsonData.theme_2?.home?.section_1?.card_section.map(
                    (img: any, imgIndex: any) => {
                      return (
                        <>
                          <li className={`${imgIndex == 2 ? "active" : ""}`}>
                            <img src={img} alt="" />
                          </li>
                        </>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="searchmodal"
        aria-hidden="true"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-md modal-dialog-centered">
          <div className="modal-content modal-content-search">
            <div className="postcodeform-container">
              <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                <div className={`${checkWithNewPin == false ? "d-none" : ""}`}>
                  <small className="text-danger postvalidation">
                    {pinCodeFormError.pinCode?.message}
                  </small>
                  <div className="postcodeform">
                    <Controller
                      name="pinCode"
                      control={pinCodeFormControl}
                      render={({ field }) => (
                        <input
                          type="text"
                          id="postcode-input"
                          {...field}
                          placeholder="Enter Postcode"
                          className={`ui-autocomplete-input ${
                            pinCodeFormError.pinCode ? "invalid" : ""
                          }`}
                        />
                      )}
                    />
                    <button
                      className="btn hover-btn"
                      id="book_now_btn"
                      type="submit"
                    >
                      Book Now
                    </button>
                  </div>
                </div>
              </form>
              <form>
                <div
                  className={`adrsselectform ${
                    checkWithNewPin == true ? "d-none" : ""
                  }`}
                >
                  <small className="text-danger postvalidation">
                    {addressFormError.address?.message}
                  </small>
                  <div className="adrsselectdd">
                    <Controller
                      name="address"
                      control={addressFormControl}
                      render={({ field }) => (
                        <Select
                          placeholder="Select your address"
                          {...field}
                          value={selectedAddress}
                          className={`${
                            addressFormError.address ? "invalid" : ""
                          }`}
                          onChange={handleAddressChange}
                          onFocus={() => {
                            if (getValuesAddressForm("address").value == "") {
                              addressFormSetError("address", {
                                type: "server",
                                message: "Address is required to proceed",
                              });
                            } else {
                              clearErrors();
                            }
                          }}
                          options={areaOptions}
                        />
                      )}
                    />

                    <input
                      type="button"
                      value="Continue"
                      className="btn hover-btn"
                      onClick={submitAddressChange}
                    />
                  </div>
                  <div className="adrsselect-or">OR</div>
                  <div className="adrsselect-link">
                    <a
                      className="cursor-pointer"
                      onClick={() => {
                        setCheckWithNewPin(true);
                      }}
                    >
                      Check with another Postcode
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionOneThemeTwo;
