import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { fetchOrderDetails } from "../../../redux/Actions/orderDetailsAction";
import TrackOrderPopup from "../../trackOrderPopup";
import { format } from "date-fns";
import {
  userimage,
  shirticon,
  shippingaddress,
  Billingaddress,
  pending,
  trackorder2,
} from "../../../assets/img";
import { log } from "console";

function OrderView() {
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [trackOrderPopup, setTrackOrderPoup] = useState(false);
  const [orderId, setOrderId] = useState<string>("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector<any>((state) => state.orderDetails.data);

  const params = useParams<any>();

  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      const { orders_details } = state;
      setOrderDetails(orders_details);
    }
  }, [state]);

  useEffect(() => {
    if (params.orderId) {
      const orderId = params.orderId;
      setOrderId(orderId);
      dispatch(fetchOrderDetails(orderId));
    }
    // fetchData();
    // dispatch(fetchMyOrders(limit,page,sort,order_status))
  }, []);

  const settings: any = useSelector<any>((state) => state.settings);

  function totalCount(orderDetails: any): number {
    var count: number = 0;
    if (orderDetails.order_items) {
      orderDetails.order_items.map((orderItem: any) => {
        orderItem.items.map((items: any) => {
          count += JSON.parse(items.item_quantity);
        });
      });
    }

    return count;
  }

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Service View</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> Service View</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-myorders">
        <div className="container">
          <div className="row">
            <div className="col-md-12 orderview-id">
              <h1>Service ID - {orderDetails.order_id}</h1>
              <span className="float-end d-none d-md-block">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/myOrders");
                  }}
                >
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  Back to my services
                </a>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-6">
              <div className="list-count">
                <div className="menswear-checkout p-3">
                  {orderDetails.order_items &&
                    orderDetails.order_items.map((orderItems: any) => (
                      <div className="orderlist-view">
                        <div className="menswear_header">
                          <span>
                            <img src={userimage} className="img-fluid" alt="" />
                          </span>
                          <span>
                            <h3>{orderItems.main_category}</h3>
                          </span>
                        </div>
                        {/* orderItems.main_category === 'Men' &&  */}
                        {orderItems.items.map((item: any) => (
                          <div className="cloth-expand">
                            <div className="menswear-body">
                              <div className="order-total">
                                <div className="row">
                                  <div className="listcloth-lft col-md-7 col-xs-7">
                                    <h2>
                                      <img
                                        src={shirticon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                      {item.sub_category}
                                    </h2>
                                    <span>{item.option_selected}</span>
                                  </div>
                                  <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                    <div className="ordered-items float-end">
                                      <span className="orderedd-item-qty">
                                        Qty : {item.item_quantity}
                                      </span>
                                      <span className="orderedd-item-price">
                                        {settings?.WebmasterSettings?.currency}{" "}
                                        {item.item_total_price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            <h2>Cotton Shirt</h2>
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                            </div>
                          </div>
                        ))}
                        {/* { orderItems.main_category === 'Kids' && 
                                            orderItems.items.map((item: any) => (
                                            <div className="cloth-expand">
                                                <div className="menswear-body">
                                                    <div className="order-total">
                                                        <div className="row">
                                                            <div className="listcloth-lft col-md-7 col-xs-7">
                                                                <h2>{item.sub_category}</h2>
                                                                <span>{item.option_selected}</span>
                                                            </div>
                                                            <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                                <div className="ordered-items float-end">
                                                                    <span className="orderedd-item-qty">Qty : {item.item_quantity}</span>
                                                                    <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} {item.item_total_price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <br />
                                            </div>
                                            ))                                         
                                            } */}
                        {/* { orderItems.main_category === 'Womens' && 
                                            orderItems.items.map((item: any) => (
                                            <div className="cloth-expand">
                                                <div className="menswear-body">
                                                    <div className="order-total">
                                                        <div className="row">
                                                            <div className="listcloth-lft col-md-7 col-xs-7">
                                                                
                                                                <span>{item.option_selected}</span>
                                                            </div>
                                                            <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                                <div className="ordered-items float-end">
                                                                    <span className="orderedd-item-qty">Qty : {item.item_quantity}</span>
                                                                    <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} {item.item_total_price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <br />
                                            </div>
                                            ))
                                            } */}
                      </div>
                    ))}
                  {/* <div className="orderlist-view">
                                        <div className="menswear_header">
                                            
                                        </div>
                                        <div className="cloth-expand">
                                            <div className="menswear-body">
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 2</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 10.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="orderlist-view">
                                        <div className="menswear_header">
                                            
                                        </div>
                                        <div className="cloth-expand">
                                            <div className="menswear-body">
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div> */}
                </div>
                <div className="menswear-checkout box">
                  <div className="ordertotal-count">
                    <div className="orders-total">
                      <span className="lft-ordertitle">Total Count</span>
                      <span className="rht-orderamt float-end">
                        {totalCount(orderDetails)}
                        {/* {orderDetails.order_items &&
                          orderDetails.order_items.map((orderItem: any) => (
                            <span>
                             {totalCount(orderItem)}
                            </span>
                          ))} */}
                      </span>
                    </div>
                    <div className="orders-total">
                      <span className="lft-ordertitle">Payment Method</span>
                      <span className="rht-orderamt float-end">
                        Debit/Credit
                      </span>
                    </div>
                    <div className="orders-total">
                      <span className="lft-ordertitle">Schedule</span>
                      {/* <span className="lft-ordertitle">Pickup</span> */}
                      <span className="rht-orderamt float-end">
                        {Object.keys(orderDetails).length > 0 &&
                          format(
                            new Date(orderDetails.pickup_date),
                            "dd-MM-yyyy"
                          )}{" "}
                        ({orderDetails.pickup_time_slot})
                      </span>
                    </div>
                    {/* <div className="orders-total">
                      <span className="lft-ordertitle">Delivery</span>
                      <span className="rht-orderamt float-end">
                        {Object.keys(orderDetails).length > 0 &&
                          format(
                            new Date(orderDetails.delivery_date),
                            "dd-MM-yyyy"
                          )}{" "}
                        ({orderDetails.delivery_time_slot})
                      </span>
                    </div> */}
                    <div className="orders-total">
                      <span className="lft-ordertitle">Amount</span>
                      <span className="rht-orderamt float-end">
                        {orderDetails.price_currency}{" "}
                        {orderDetails.price_before_discount}
                      </span>
                    </div>
                    <div className="orders-total">
                      <span className="lft-ordertitle">VAT</span>
                      <span className="rht-orderamt float-end">
                        ({orderDetails.vat_percentage}%){" "}
                        {orderDetails.price_currency} {orderDetails.vat_amount}
                      </span>
                    </div>
                    <div className="orders-total">
                      <span className="lft-ordertitle">Total Amount</span>
                      <span className="rht-orderamt float-end ordertotal-amt">
                        {orderDetails.price_currency} {orderDetails.total_amout}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-12 fb-section hidden">
                                <h3>FeedBack</h3>
                            </div> */}
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="ordertotal">
                <h2>
                  <img src={shippingaddress} className="img-fluid" alt="" />
                  Location{/* Shipping Address */}
                </h2>
                <div className="orders-total">
                  {orderDetails.pickup_address && (
                    <p>{orderDetails.pickup_address.text_address}</p>
                  )}
                </div>
              </div>
              <div className="ordertotal">
                <h2>
                  <img src={Billingaddress} className="img-fluid" alt="" />{" "}
                  Billing Address
                </h2>
                <div className="orders-total">
                  {/* Flat 1, Park View House, 7 High Street, Aldershot, Hampshire, GU111BH */}
                  {orderDetails.billing_address && (
                    <p>{orderDetails.billing_address.text_address}</p>
                  )}
                </div>
              </div>
              <div className="btn_thankyou_mob">
                <ul className="order-status">
                  <li className="order-pending float-start">
                    <img src={pending} className="img-fluid" alt="" />
                    {orderDetails.cancelled == "1"
                      ? "Cancelled"
                      : orderDetails.order_status &&
                        orderDetails.order_status.current_status}
                  </li>
                  {orderDetails.cancelled == "0" && (
                    <li className="float-end">
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setTrackOrderPoup(!trackOrderPopup);
                        }}
                        className="order-track"
                        data-bs-toggle="modal"
                        data-bs-target="#8QT3BN"
                      >
                        <img src={trackorder2} className="img-fluid" alt="" />
                        Order Status
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="8QT3BN" className="modal fade" role="dialog">
                    <div className="modal-dialog vporgress-modaldialog">

                        <div className="modal-content vprogress-content">
                            <div className="modal-header">
                                Order ID - 8QT3BN
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body vprogress-body">
                                <div className="vprogress">
                                    <div className="circle current-status">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span>Tuesday 06-12-2022, 12:09</span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span>Expeceted by Tuesday 06-12-2022 (7PM - 8PM)</span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span>Expeceted by Sunday 11-12-2022 (11AM - 12AM)</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer vprogress-footer">
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
      {trackOrderPopup && (
        <TrackOrderPopup
          close={() => setTrackOrderPoup(false)}
          orderId={orderId}
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default OrderView;
