import React from "react";
import { useSelector } from "react-redux/es/exports";
import SectionTwelveBannerThemeThree from "./SectionTwelveBannerThemeThree";
import SectionOneBannerThemeThree from "./SectionOneBannerThemeThree";
import SectionElevenThemeThree from "./SectionElevenThemeThree";
import SectionThirteenThemeThree from "./SectionThirteenThemeThree";
import SectionFifteenThemeThree from "./SectionFifteenThemeThree";
import SectionThreeThemeThree from "./sectionThreeThemeThree";
import SectionFourThemeThree from "./sectionFourThemeThree";
import SectionFiveThemeThree from "./sectionFiveThemeThree";
import SectionSixThemeThree from "./sectionSixThemeThree";
import SectionSevenThemeThree from "./sectionSevenThemeThree";
import SectionEightThemeThree from "./SectionEightThemeThree";
import SectionTenBannerThemeThree from "./SectionTenBannerThemeThree";
import SectionNineThemeThree from "./sectionNineThemeThree";
import SectionOffer from "../theme-one/section-offer";

function ThemeThree() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 1) {
      return jsonData?.theme_1?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 3) {
      return jsonData?.theme_3?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 5) {
      return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    }
  };

  return (
    <>
      {/* Theme Three */}
      {settingsData?.template == 3 && (
        <>
        
          {checkIsSectionEnabled(0) && <SectionOneBannerThemeThree />}
          {/* {checkIsSectionEnabled(1) && <SectionTwoBannerThemeThree />} */}
          {checkIsSectionEnabled(2) && <SectionThreeThemeThree />}
          <SectionOffer/>
          {checkIsSectionEnabled(3) && <SectionFourThemeThree />}
          {checkIsSectionEnabled(4) && <SectionFiveThemeThree />}
          {checkIsSectionEnabled(5) && <SectionSixThemeThree />}
          {checkIsSectionEnabled(6) && <SectionSevenThemeThree />}
          {checkIsSectionEnabled(7) && <SectionEightThemeThree />}
          {checkIsSectionEnabled(8) && <SectionNineThemeThree />}
          {checkIsSectionEnabled(9) && <SectionTenBannerThemeThree />}
          {checkIsSectionEnabled(10) && <SectionElevenThemeThree />}
          {checkIsSectionEnabled(11) && <SectionTwelveBannerThemeThree />}
          {checkIsSectionEnabled(12) && <SectionThirteenThemeThree />}
          {/* 14 need to confirm */}
          {checkIsSectionEnabled(14) && <SectionFifteenThemeThree />}
        </>
      )}
    </>
  );
}

export default ThemeThree;
