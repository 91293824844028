import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHomeJsonList, saveThemeJsonData } from "../../../redux/Actions";

const SectionNineBannerThemeTwo = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>({});
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_2: {
        ...prevJsonData.theme_2,
        home: {
          ...prevJsonData.theme_2.home,
          section_9: {
            ...prevJsonData.theme_2.home.section_9,
            [name]: value,
          },
        },
      },
    }));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Nine Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 120 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={editData?.theme_2?.home?.section_9?.title}
                      onChange={handleChange}
                      maxLength={120}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_2?.home?.section_9?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Android Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="android_link"
                      value={editData?.theme_2?.home?.section_9?.android_link}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">IOS Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ios_link"
                      value={editData?.theme_2?.home?.section_9?.ios_link}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionNineBannerThemeTwo;
