import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import { useSelector } from "react-redux";

import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import DownloadApp from "../downloadApp";
import {
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  eco,
  priorityicon14,
  priorityicon15,
  priorityicon16,
  priorityicon17,
  appdownload,
  appstore,
  googleplay,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  trustimg,
  trustimg2,
  trustimg3,
  trustimg4,
  howwe1,
  howwe2,
  howwe3,
  howwe4,
} from "../../assets/img";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function SectionSixThemeFour() {
  //const state: any = useSelector((state) => state);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  const navigate = useNavigate();
  useEffect(() => {
 }, [jsonData]);
  
  return (
    <>
      <section className="serv-section" id="service-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2>{jsonData?.theme_4?.home?.section_6?.title}</h2>

                {/* <h2>service for Business & Personal</h2> */}
                <p>{jsonData?.theme_4?.home?.section_6?.paragraph}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="split-img">
                <img
                  src={jsonData?.theme_4?.home?.section_6?.first_card_image}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="serv-content">
                <h4>{jsonData?.theme_4?.home?.section_6?.sub_title}</h4>

                <ul>
                  {jsonData?.theme_4?.home?.section_6?.card_section?.collection.map(
                    (item: any) => {
                      return (
                        <>
                          <li>{item?.collection_list}</li>
                        </>
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="serv-content">
                <h4>{jsonData?.theme_4?.home?.section_6?.sub_title_two}</h4>
                <ul>
                  {jsonData?.theme_4?.home?.section_6?.card_section_two?.collection.map(
                    (item: any) => {
                      return (
                        <>
                          <li>{item?.collection_list}</li>
                        </>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSixThemeFour;
