import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import ImageCropper from "../../imageCropper/imageCropper";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";

function SectionEightThemeFourEdit() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const [activeTab, setActiveTab] = useState(0);
  const [activeTabTwo, setActiveTabTwo] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [showCropModalTwo, setShowCropModalTwo] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const data: any = useSelector<any>((state) => state.homeJsonList);
  const section8 = editData?.theme_4?.home?.section_8;
  const bannerImages: Array<string> = [
    "assets/home/trustimg.jpg",
    "assets/home/trustimg3.png",
    "assets/home/trustimg4.jpg",
    "assets/home/trustimg2.jpg",
    "assets/home/trustimg.jpg",
    "assets/home/trustimg3.png",
    "assets/home/trustimg4.jpg",
    "assets/home/trustimg2.jpg",
  ];
  const icons = [
    "assets/icons/priorityicon1.png",
    "assets/icons/priorityicon2.png",
    "assets/icons/priorityicon3.png",
    "assets/icons/priorityicon4.png",
    "assets/icons/priorityicon5.png",
    "assets/icons/priorityicon6.png",
    "assets/icons/priorityicon7.png",
    "assets/icons/priorityicon8.png",
    "assets/icons/priorityicon9.png",
    "assets/icons/priorityicon10.png",
    "assets/icons/priorityicon11.png",
    "assets/icons/priorityicon12.png",
    "assets/icons/priorityicon13.png",
    "assets/icons/priorityicon14.png",
    "assets/icons/priorityicon15.png",
    "assets/icons/priorityicon16.png",
    "assets/icons/priorityicon17.png",
  ];
  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };
  const handleChange = (e: any, index?: any, part?: string) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [];
    if (index != undefined) {
      if (part == "part_one") {
        updatedCollection = [
          ...editData.theme_4.home.section_8.part_one.card_section.collection,
        ];
      } else {
        updatedCollection = [
          ...editData.theme_4.home.section_8.part_two.card_section.collection,
        ];
      }
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        home: {
          ...prevJsonData.theme_4.home,
          section_8: {
            ...prevJsonData.theme_4.home.section_8,
            part_one: {
              ...prevJsonData.theme_4.home.section_8.part_one,
              [name]: value,
              card_section: {
                collection:
                  part == "part_one"
                    ? updatedCollection
                    : prevJsonData.theme_4.home.section_8.part_one.card_section
                        .collection,
              },
            },
            part_two: {
              ...prevJsonData.theme_4.home.section_8.part_two,
              [name]: value,
              card_section: {
                collection:
                  part == "part_two"
                    ? updatedCollection
                    : prevJsonData.theme_4.home.section_8.part_two.card_section
                        .collection,
              },
            },
          },
        },
      },
    }));
  };
  const handleBannerImageChange = (image: string) => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        home: {
          ...prevJsonData.theme_4.home,
          section_8: {
            ...prevJsonData.theme_4.home.section_8,
            part_one: {
              ...prevJsonData.theme_4.home.section_8.part_one,
              section_image: image,
            },
          },
        },
      },
    }));
  };
  const removeCollectionPartOne = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_8.part_one.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setActiveTab(activeTab - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        home: {
          ...prevJsonData.theme_4.home,
          section_8: {
            ...prevJsonData.theme_4.home.section_8,
            part_one: {
              ...prevJsonData.theme_4.home.section_8.part_one,
              card_section: {
                collection: updatedCollection,
              },
            },
          },
        },
      },
    }));
  };
  const addCollectionPartOne = () => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_8.part_one.card_section.collection,
    ];

    let newCard = {
      collection_title: "",
      collection_paragraph: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        home: {
          ...prevJsonData.theme_4.home,
          section_8: {
            ...prevJsonData.theme_4.home.section_8,
            part_one: {
              ...prevJsonData.theme_4.home.section_8.part_one,
              card_section: {
                collection: updatedCollection,
              },
            },
          },
        },
      },
    }));
  };
  const removeCollectionPartTwo = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_8.part_two.card_section.collection,
    ];
    updatedCollection.splice(index, 1);
    setActiveTabTwo(activeTabTwo - 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        home: {
          ...prevJsonData.theme_4.home,
          section_8: {
            ...prevJsonData.theme_4.home.section_8,
            part_two: {
              ...prevJsonData.theme_4.home.section_8.part_two,
              card_section: {
                collection: updatedCollection,
              },
            },
          },
        },
      },
    }));
  };
  const addCollectionPartTwo = () => {
    let updatedCollection: any = [
      ...editData.theme_4.home.section_8.part_two.card_section.collection,
    ];

    let newCard = {
      collection_title: "",
      collection_paragraph: "",
    };
    updatedCollection.splice(activeTabTwo + 1, 0, newCard);
    setActiveTabTwo(activeTabTwo + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_4: {
        home: {
          ...prevJsonData.theme_4.home,
          section_8: {
            ...prevJsonData.theme_4.home.section_8,
            part_two: {
              ...prevJsonData.theme_4.home.section_8.part_two,
              card_section: {
                collection: updatedCollection,
              },
            },
          },
        },
      },
    }));
  };
  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleBannerImageChange(imageLink);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    setCropImageHeight("150");
    setCropImageWidth("150");
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  useEffect(() => {
    setEditData(data);
  }, [data]);
  useEffect(() => {
    if (editData != undefined) {
      // dispatch(fetchHomeJsonList(editData));
      setEditData(editData);
    }
  }, [editData]);

  return (
    <>
      {/* <Draggable> */}
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section8 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col-6">
                  <div className=" mb-3">
                    <label className="form-label">Section Two Bg Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="bg_color"
                        value={
                          editData?.theme_4?.home?.section_8?.part_two?.bg_color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="bg_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_4?.home?.section_8?.part_two?.bg_color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className=" mb-3">
                    <label className="form-label">Section Two Text Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="text_color"
                        value={
                          editData?.theme_4?.home?.section_8?.part_two
                            ?.text_color
                        }
                        onChange={handleChange}
                      />
                      <ColorPicker
                        name="text_color"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        format="hex"
                        value={
                          editData?.theme_4?.home?.section_8?.part_two
                            ?.text_color
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 120 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      value={section8?.part_one?.title}
                      onChange={(e) => {
                        if (e.target.value.length < 120) {
                          handleChange(e);
                        }
                      }}
                      maxLength={120}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Sub Title</label>
                    <input
                      type="text"
                      className="form-control"
                      name="sub_title"
                      value={section8?.part_one?.sub_title}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={section8?.part_one?.paragraph}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Upload Image</label>
                    <input
                      type="file"
                      accept="image/png, image/jpeg"
                      className="form-control"
                      name="image"
                      onChange={updateImageSectionOne}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="img-bg active">
                          <img
                            src={section8?.part_one?.section_image}
                            alt="Hero Image"
                          />
                        </div>
                        <div className="img-options">
                          <div className="row">
                            <div className="col-md-12">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {bannerImages.map(
                                  (images: any, imgIndex: any) => {
                                    return (
                                      <>
                                        <SwiperSlide key={imgIndex}>
                                          <div className="img-option">
                                            <img
                                              onClick={() => {
                                                handleBannerImageChange(images);
                                              }}
                                              src={images}
                                              alt="Hero Image"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </SwiperSlide>
                                      </>
                                    );
                                  }
                                )}
                              </Swiper>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_4?.home?.section_8?.part_one?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollectionPartOne(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_4?.home?.section_8?.part_one?.card_section?.collection.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="mb-3">
                        <label className="form-label">
                          Title<small>(max 120 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_title"
                          value={card.collection_title}
                          onChange={(e) => handleChange(e, index, "part_one")}
                          maxLength={120}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Paragraph</label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_paragraph"
                          value={card.collection_paragraph}
                          onChange={(e) => handleChange(e, index, "part_one")}
                          maxLength={1200}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollectionPartOne();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_4?.home?.section_8?.part_two?.card_section?.collection.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTabTwo === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTabTwo(index)}
                          >
                            Card Section {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollectionPartTwo(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_4?.home?.section_8?.part_two?.card_section?.collection.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTabTwo === index ? "show active" : ""
                      }`}
                    >
                      <div className="mb-3">
                        <label className="form-label">
                          Title<small>(max 120 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_title"
                          value={card.collection_title}
                          onChange={(e) => handleChange(e, index, "part_two")}
                          maxLength={120}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Paragraph</label>
                        <input
                          type="text"
                          className="form-control"
                          name="collection_paragraph"
                          value={card.collection_paragraph}
                          onChange={(e) => handleChange(e, index, "part_two")}
                          maxLength={1200}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollectionPartTwo();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* </Draggable> */}
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionEightThemeFourEdit;
