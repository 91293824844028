import React, { useState } from "react";
import Home from "../../pages/Home";
import Pricing from "./../../pages/pricing";
import { Route, Routes, Link } from "react-router-dom";
import AreaCovered from "../../pages/areaCovered";
import Header from "../header/header";
import Footer from "../footer";
import FAQ from "../../pages/faq";
import MyProfile from "./../profile/myProfile/myProfile";
import MyAddress from "../profile/myAddress/myAddress";
import RushMoor from "../rushmoor";
import MyOrders from "../profile/myOrders";
import OrderView from "../profile/orderView";
import AboutUs from "../aboutUs";
import Reviews from "../reviews";
import Login from "../login";
import ProductLists from "../productLists";
import CheckOut from "../checkout";
import ForgotPassword from "../forgotPassword";
import AreaNotCovered from "../areaNotCovered";
import GuestLogin from "../guestLogin";
import ResetPassword from "../resetPassword";
import ThankYou from "../thankYou";
import AllNotifications from "../notification/allNotifications";
import EditProfile from "../profile/myProfile/editProfile";
import NewAddress from "../profile/myAddress/newAddress";
import EditAddress from "../profile/myAddress/editAddress";
import TermsAndConditions from "../termsAndConditions";
import PrivacyPolicy from "../privacyPolicy";
import CookiePolicy from "../cookiePolicy";
import ReferFriend from "../profile/referFriend";
import ShopHome from "../shopHome";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import PrivateRoute from "./privateRoutes";
import * as PusherPushNotifications from "@pusher/push-notifications-web";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import ScrollToTop from "../scrolltoTop/index";
import SignUp from "../signUp";
import Edit from "../edit";
import HeaderOne from "../header/headerOne";
import HeaderTwo from "../header/headerTwo";
import HeaderThree from "../header/headerThree";
import HeaderFour from "../header/headerFour";
import HeaderFive from "../header/headerFive";
import { useDispatch, useSelector } from "react-redux/es/exports";
import FooterOne from "../footer/footerOne";
import FooterTwo from "../footer/footerTwo";
import FooterThree from "../footer/footerThree";
import FooterFour from "../footer/footerFour";
import FooterFive from "../footer/footerFive";
import { getSettingsData } from "../../redux/Actions";
import SpecialRequest from "../specialRequest/specialRequest";

const userData = { name: "John", token: "test" };

function App() {
  const dispatch = useDispatch<any>();

  const settingsData: any = useSelector<any>((state) => state?.settings);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    () => localStorage.getItem("logged_user") !== null
  );
  const navigate = useNavigate();
  const location = useLocation();
  let url = location.pathname;
  let splitURL = url.toString().split("/");
  // window.navigator.serviceWorker.ready.then(serviceWorkerRegistration => {
  // }
  // );

  useEffect(() => {
    let host = window.location.hostname;
    let origin = "https://demo2.bestatlaundry.com";
    let companyName = "";

    if (host !== "localhost") {
      origin = window.location.origin;
      let client = origin.split("/");
      client = client[client.length - 1].split(".");
      companyName = client[0];
    }

    if (
      host.includes("Sheer Cleaning") ||
      settingsData?.WebsiteSettings?.site_title_en.includes("Sheer Cleaning")
    ) {
      import("../../assets/css/sheer.css");
    }
    // if (settingsData?.template == 1) {
    //   import("../../assets/css/style1.css");
    // } else if (settingsData?.template == 2) {
    //   import("../../assets/css/style2.css");
    // } else if (settingsData?.template == 3) {
    //   import("../../assets/css/style3.css");
    // } else if (settingsData?.template == 4) {
    //   import("../../assets/css/style4.css");
    // } else if (settingsData?.template == 5) {
    //   import("../../assets/css/style5.css");
    // }
  }, [settingsData]);

  useEffect(() => {
    localStorage.setItem("logged_user", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  const logIn: any = () => setIsLoggedIn(true);
  const logOut = () => setIsLoggedIn(false);

  // useEffect(()=>{
  //   const userInfo = JSON.parse(localStorage.getItem('userDetails')!);
  //   if(userInfo){
  //     navigate('/myProfile');
  //   }else{
  //     navigate('/login');
  //   }
  // })
  const checkHeader = () => {
    return splitURL[1] !== "shopHome" && splitURL[1] !== "edit";
  };
  useEffect(() => {
    dispatch(getSettingsData());
    console.log(settingsData);
  }, []);
  return (
    <>
      <ScrollToTop>
        {checkHeader() && settingsData?.template == 1 && <HeaderOne />}
        {checkHeader() && settingsData?.template == 2 && <HeaderTwo />}
        {checkHeader() && settingsData?.template == 3 && <HeaderThree />}
        {checkHeader() && settingsData?.template == 4 && <HeaderFour />}
        {checkHeader() && settingsData?.template == 5 && <HeaderFive />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/areaCovered" element={<AreaCovered />} />
          <Route path="/notifications" element={<AllNotifications />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/special-request" element={<SpecialRequest />} />

          <Route
            path="/myProfile"
            element={
              <PrivateRoute>
                {" "}
                <MyProfile />{" "}
              </PrivateRoute>
            }
          />
          <Route
            path="/editProfile"
            element={
              <PrivateRoute>
                <EditProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="/myAddress"
            element={
              <PrivateRoute>
                <MyAddress />
              </PrivateRoute>
            }
          />

          <Route
            path="/newAddress"
            element={
              <PrivateRoute>
                <NewAddress />
              </PrivateRoute>
            }
          />
          <Route
            path="/editAddress/:address"
            element={
              <PrivateRoute>
                <EditAddress />
              </PrivateRoute>
            }
          />
          <Route path="/service/:location" element={<RushMoor />} />
          <Route
            path="/myOrders"
            element={
              <PrivateRoute>
                <MyOrders />
              </PrivateRoute>
            }
          />
          <Route
            path="/orderView/:orderId"
            element={
              <PrivateRoute>
                <OrderView />
              </PrivateRoute>
            }
          />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/login/:tokenref" element={<Login />} />
          <Route path="/productLists" element={<ProductLists />} />
          <Route path="/checkOut" element={<CheckOut />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/areaNotCovered" element={<AreaNotCovered />} />
          <Route path="/guestLogin" element={<GuestLogin />} />
          <Route path="/resetPassword/:token" element={<ResetPassword />} />
          <Route path="/thankYou" element={<ThankYou />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/cookiePolicy" element={<CookiePolicy />} />
          <Route path="/referFriend" element={<ReferFriend />} />
          <Route path="/shopHome/:finalResult" element={<ShopHome />} />
          <Route path="/edit" element={<Edit />} />
        </Routes>
        {checkHeader() && settingsData?.template === 1 && <FooterOne />}
        {checkHeader() && settingsData?.template === 2 && <FooterTwo />}
        {checkHeader() && settingsData?.template === 3 && <FooterThree />}
        {checkHeader() && settingsData?.template === 4 && <FooterFour />}
        {checkHeader() && settingsData?.template === 5 && <FooterFive />}
      </ScrollToTop>
    </>
  );
}

export default App;
