import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import ImageCropper from "../../imageCropper/imageCropper";

const SectionFiveThemeOneEdit = () => {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const data: any = useSelector<any>((state) => state.homeJsonList);
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);

  const icons = [
    "assets/img/icons/priorityicon1.svg",
    "assets/img/icons/priorityicon2.svg",
    "assets/img/icons/priorityicon3.svg",
    "assets/img/icons/priorityicon1.svg",
    "assets/img/icons/priorityicon2.svg",
    "assets/img/icons/priorityicon3.svg",
    "assets/img/icons/priorityicon1.svg",
    "assets/img/icons/priorityicon2.svg",
    "assets/img/icons/priorityicon3.svg",
  ];

  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(data);
  }, [data]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const addCollection = () => {
    let updatedCollection: any = [...editData.theme_1.home.section_5.check_list];

    let newCard = {
      icons: "assets/img/icons/priorityicon1.svg",
      title: "",
      count: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1:{
        home: {
          ...prevJsonData.theme_1.home,
          section_5: {
            ...prevJsonData.theme_1.home.section_5,
            check_list: updatedCollection,
          },
        },
      }
    
    }));
  };

  const removeCollection = (index: any) => {
    let updatedCollection: any = [...editData.theme_1.home.section_5.check_list];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1:{
        home: {
          ...prevJsonData.theme_1.home,
          section_5: {
            ...prevJsonData.theme_1.home.section_5,
            check_list: updatedCollection,
          },
        },
      }
    
    }));
  };

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [...editData.theme_1.home.section_5.check_list];
    if (index != undefined) {
      updatedCollection = [...editData.theme_1.home.section_5.check_list];
      updatedCollection[index] = {
        ...updatedCollection[index],
        [name]: value,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1:{
        home: {
          ...prevJsonData.theme_1.home,
          section_5: {
            ...data.home.section_5,
            [name]: value,
            title:
              name === "section_5.title"
                ? value
                : prevJsonData.theme_1.home.section_5.title,
            mobile_link_section: {
              ...prevJsonData.theme_1.home.section_5.mobile_link_section,
              [name]: value,
            },
            check_list: updatedCollection,
          },
        },
      }
    
    }));
  };

  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [...editData.theme_1.home.section_5.check_list];
    updatedCollection[index] = {
      ...updatedCollection[index],
      icons: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_1:{
        home: {
          ...prevJsonData.theme_1.home,
          section_5: {
            ...prevJsonData.theme_1.home.section_5,
            ...prevJsonData.theme_1.home.section_5.check_list,
            check_list: updatedCollection,
          },
        },
      }
     
    }));
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink, activeTab);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    setCropImageHeight("150");
    setCropImageWidth("150");
    setSelectedCropImage(image);
    setShowCropModal(true);
  };

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section Five Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 120 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_5.title"
                      value={editData?.theme_1?.home?.section_5?.title}
                      onChange={handleChange}
                      maxLength={120}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_1?.home?.section_5?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_1?.home?.section_5?.check_list.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Check List {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_1?.home?.section_5?.check_list.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.icons}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                            <Swiper
                              spaceBetween={15}
                              navigation={true}
                              modules={[Navigation]}
                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                },
                                768: {
                                  slidesPerView: 4,
                                },
                                1024: {
                                  slidesPerView: 6,
                                },
                              }}
                              className="homejson-slider"
                            >
                              {icons.map((icons, iconIndex) => (
                                <SwiperSlide key={iconIndex}>
                                  <div
                                    className="img-option"
                                    onClick={() =>
                                      handleImageChange(icons, index)
                                    }
                                  >
                                    <img
                                      src={icons}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              ))}
                            </Swiper>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Title<small>(max 120 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          value={card.title}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={120}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Count<small>(max 10 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="count"
                          value={card.count}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={10}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <h4>Mobile Link Section</h4>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Android Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="android_link"
                      value={
                        editData?.theme_1?.home?.section_5?.mobile_link_section
                          ?.android_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Ios Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ios_link"
                      value={
                        editData?.theme_1?.home?.section_5?.mobile_link_section?.ios_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};

export default SectionFiveThemeOneEdit;
