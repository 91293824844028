import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getHomeJsonList, getSettingsData } from "../../redux/Actions";
import ThemeTwo from "../../components/theme-two";
import ThemeFour from "../../components/theme-four";
import ThemeFive from "../../components/theme-five";
import ThemeOne from "../../components/theme-one";
import ThemeThree from "../../components/theme-three";

function Home() {
  const dispatch = useDispatch<any>();
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);

  useEffect(() => {
    dispatch(getHomeJsonList());
  }, []);

  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 1) {
      return jsonData?.theme_1?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 3) {
      return jsonData?.theme_3?.home?.sections[index]?.is_section_enable;
    }
    if (settingsData?.template == 5) {
      return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    }
  };
  useEffect(() => {
    dispatch(getSettingsData());
  }, []);
  return (
    <div className="sec-home">
      {/* Theme One */}
      {settingsData?.template == 1 && <ThemeOne />}
      {/* Theme One */}

      {/* Theme Two */}
      {settingsData?.template == 2 && <ThemeTwo />}
      {/* Theme Two */}

      {/* Theme Three */}
      {settingsData?.template == 3 && <ThemeThree />}

      {/* Theme Four */}
      {settingsData?.template == 4 && <ThemeFour />}
      {/* Theme Four */}

      {/* Theme Five */}
      {settingsData?.template == 5 && <ThemeFive />}
      {/* Theme Five */}
    </div>
  );
}

export default Home;
