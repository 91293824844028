import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";

function SectionSixThemeTwo() {
  const dispatch = useDispatch<any>();
 // const faqData: any = useSelector<any>((state) => state?.faq);
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  useEffect(() => {
    dispatch(fetchFaqData());
  }, []);

  return (
    <>
      <section className="faq-section">
        <div className="container">
          <div className="row justify-content-end">
            <div className="col-md-12">
              <div className="section-card">
                <div className="section-title2">
                  <h2>Frequently asked questions</h2>
                </div>
                <div className="faq-content">
                    {homedata?.theme_2?.home?.section_6?.card_section?.collection?.map((item: any) => {
                      return (
                        <Accordion defaultActiveKey={["1"]} className="faq-home">

                        <Accordion.Item eventKey={item?.id}>
                          <Accordion.Header>{item?.collection_title}</Accordion.Header>
                          <Accordion.Body>{item?.collection_paragraph}</Accordion.Body>
                        </Accordion.Item>
                        </Accordion>
  
                      );
                    })}
                    
                    {/* <Accordion.Item eventKey={"2"}>
                                    <Accordion.Header>What's the turnaround time?</Accordion.Header>
                                    <Accordion.Body>Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers. Bestatlaundry have multiple registered drivers and trusted dry cleaners & laundry service providers to guarantee our successful services delivery to our customers.</Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey={"3"}>
                                    <Accordion.Header>How will I know how much my bill is?</Accordion.Header>
                                    <Accordion.Body>Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers. Bestatlaundry have multiple registered drivers and trusted dry cleaners & laundry service providers to guarantee our successful services delivery to our customers.</Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey={"4"}>
                                    <Accordion.Header>Why Choose Our On-Demand Delivery Services?</Accordion.Header>
                                    <Accordion.Body>Bestatlaundry is synonymous with quality, reliability, and trust. We provide fast dry cleaning and laundry services in and around London. Advance online technology and modern process and equipment utilization means affordable cost to all our customers. Bestatlaundry have multiple registered drivers and trusted dry cleaners & laundry service providers to guarantee our successful services delivery to our customers.</Accordion.Body>
                                    </Accordion.Item> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSixThemeTwo;
